<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
// import API from '@/api.js';

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.$auth.login({
            email: this.email,
            password: this.password
          }).catch((error) => {
            console.log(error);
            this.isAuthError = true;
            this.authError = "Errore di autenticazione. Verificare che e-mail e password siano corretti";
          });
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid" style="overflow-x: hidden;">
    <div class="row" style="min-height: 100vh;">
      <div class="col-lg-5 login-side-col bg-primary">
        <div class="row h-100">
          <div class="col-12 align-self-center">
            <img class="my-auto align-self-center" :src="require('@/assets/images/logo-ae-w-alt.png')" />
          </div>
        </div>
      </div>
      <div class="col-lg-7 align-self-center h-100" style="">
          <div clas="row justify-items-center">
            <div class="col-6 mx-auto">
              <div class="text-center mt-2 align-self-center">
                  <h3 class="text-primary">Bentornato!</h3>
                  <p class="text-muted">Accedi ad AdaptaEasy Manager</p>
                </div>
            </div>
            <div class="col-lg-8 col-md-10 col-12 mx-auto align-self-center">
              <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Indirizzo e-mail"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Inserisci l'indirizzo e-mail"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Indirizzo e-mail obbligatiorio.</span
                        >
                        <span v-if="!$v.email.email"
                          >Indirizzo e-mail non valido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <!-- <div class="float-end">
                        <router-link
                          to="/forgot-password"
                          class="text-muted"
                          >Password dimenticata?</router-link
                        >
                      </div> -->
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Inserisci la password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password obbligatoria.
                      </div>
                    </b-form-group>
                    <!-- <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Ricordami</label
                      >
                    </div> -->
                    <div class="mt-3 text-center">
                      <b-button type="submit" variant="primary" class="w-sm"
                        >Accedi</b-button
                      > &nbsp; &nbsp;
                      <router-link to="/forgot-password" class="btn btn-outline-primary w-sm ml-4">
                        Ho dimenticato la password
                      </router-link>
                    </div>
                    <div class="mt-3 text-center">
                      Non hai ricevuto il link di attivazione oppure è scaduto?
                      <router-link to="/resend-activation">
                         <b>Clicca qui</b>
                      </router-link>
                    </div>
                  </b-form>
              </div>
            </div>
            <div class="col-12 align-self-end">
              <p class="mt-5 text-center">
                © {{ new Date().getFullYear() }} - Powered by Vysio s.r.l. <br/> P.IVA: IT05156040759 - Via Carlo Magno 17 73042 Casarano (Le) Italia
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-login {
  background-color: #171f36;  
}
.login-side-col {
  text-align: center;
}
.login-side-col img {
    width: 40%;
}
</style>
